
import axios from 'axios'


const registerApi = axios.create({
    baseURL: 'https://api.purocrono.com'
    // baseURL: 'http://localhost:8000/'
    // baseURL: 'https://apipurocrono.chdesarrolloweb.com'
})


export default registerApi


